const CollectionMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'collection.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'New Accounts',
    is_heading: false,
    is_active: false,
    link: {
      name: 'collection.new-accounts'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-contacts-book-line'
  }
]

export default CollectionMenu
