<template>
  <div
    id="iq-sidebar"
    class="iq-sidebar"
  >
    <div
      class="iq-sidebar-logo d-flex justify-content-between align-items-center"
      style="min-height: 77px;"
    >
      <router-link :to="{ name: `${user ? user.role : 'admin'}.dashboard`}">
        <!-- <div class="iq-light-logo">
          <img
            src="@/assets/images/aljay-logo.png"
            class="img-fluid"
            alt="logo"
          >
        </div>
        <div class="iq-dark-logo">
          <img
            src="@/assets/images/aljay-logo.png"
            class="img-fluid"
            alt="logo"
          >
        </div> -->
        <span>&nbsp;</span>
      </router-link>
      <div
        class="iq-menu-bt-sidebar"
        style="margin-top: 17px;"
      >
        <div class="iq-menu-bt align-self-center">
          <div
            class="wrapper-menu"
            @click="sidebarMini"
          >
            <div class="main-circle">
              <i class="ri-arrow-left-s-line" />
            </div>
            <div class="hover-circle">
              <i class="ri-close-line" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <CollapseMenu
          :items="menuItems"
          :open="true"
          :sidebar-group-title="true"
        />
      </nav>
      <div class="p-3" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { core, APPNAME } from '@/config/pluginInit'
import CollapseMenu from '@/components/core/menus/CollapseMenu'
import { UserSideMenu, DSMMenu, CollectionMenu, SOMMenu, AgentSideMenu, LeadSupervisorSideMenu, SupervisorSideMenu, ManagerSideMenu, AdminSideMenu } from '@/navigation'

export default {
  name: 'SidebarStyle',
  components: {
    CollapseMenu
  },

  data () {
    return {
      appName: APPNAME
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    menuItems () {
      const user = this.$store.getters['auth/user']

      if (user) {
        switch (user.role) {
          case 'user' : {
            if (user.user_scope === 'retail') {
              return UserSideMenu.filter(item => {
                if (item.name === 'sidebar.user.reports' && item?.children.length > 0) {
                  item.children = item.children.filter(children => children?.title !== 'Visit Histories')
                }

                return item.title !== 'Pending Visits' && item.title !== 'Facebook Raw Data'
              })
            }
            return UserSideMenu
          }
          case 'agent' : {
            return AgentSideMenu
          }
          case 'dsm' : {
            return DSMMenu
          }
          case 'som' : {
            return SOMMenu
          }
          case 'collection' : {
            return CollectionMenu
          }
          case 'supervisor' : {
            return SupervisorSideMenu
          }
          case 'lead-supervisor' : {
            return LeadSupervisorSideMenu
          }
          case 'manager' : {
            return ManagerSideMenu
          }
          case 'admin' : {
            return AdminSideMenu
          }
          default: {
            return []
          }
        }
      }

      return []
    }
  },

  mounted () {
    core.SmoothScrollbar()
  },

  methods: {
    sidebarMini () {
      core.triggerSet()
    }
  }
}
</script>
