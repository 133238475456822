const DSMMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'dsm.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  // {
  //   title: 'New Accounts',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'dsm.new-accounts'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'ri-contacts-book-line'
  // },
  {
    title: 'My Dealers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'dsm.my-dealers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-contacts-book-line'
  },
  {
    title: 'My Activities',
    is_heading: false,
    is_active: false,
    link: {
      name: 'dsm.my-activities'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-profile-line'
  }
]

export default DSMMenu
