const ManagerSideMenu = [
  {
    title: 'Customer Information',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Crops',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.crops'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-stack-line'
  },
  {
    title: 'Varieties',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.varieties'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-device-line'
  },

  {
    title: 'Herbicides',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.herbicides'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-function-line'
  },

  {
    title: 'Herbicides (Dike)',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.herbicides-dike'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-function-fill'
  },
  {
    title: 'Fungicides',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.fungicides'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-bug-line'
  },
  {
    title: 'Insecticides',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.insecticides'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-contrast-drop-line'
  },
  {
    title: 'Fertilizers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.fertilizers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-paint-line'
  },
  {
    title: 'Crop Prices',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.crop-prices'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-money-dollar-circle-line'
  },
  {
    title: 'Raisers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'manager.raisers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-baidu-line'
  }
]

export default ManagerSideMenu
