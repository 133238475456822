const SupervisorSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Monitoring',
    name: 'sidebar.supervisor.monitoring',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'LGA Raw Data',
        is_active: false,
        link: {
          name: 'supervisor.lga-raw-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      },
      {
        title: 'LGA Tickets',
        is_active: false,
        link: {
          name: 'supervisor.lga-tickets'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      },
      {
        title: 'CSO Raw Data',
        is_active: false,
        link: {
          name: 'supervisor.cso-raw-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      }
    ]
  },
  {
    title: 'Raw Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.raw-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-line'
  },
  {
    title: 'Campaign Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.campaign-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-flag-line'
  },
  {
    title: 'Master Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.master-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-group-line'
  },
  {
    title: 'Maintenances',
    name: 'sidebar.supervisor.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Channels',
        is_active: false,
        link: {
          name: 'supervisor.channels'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-switch-line'
      },
      {
        title: 'Disposition Types',
        is_active: false,
        link: {
          name: 'supervisor.disposition-types'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-unknow-line'
      },
      {
        title: 'Dispositions',
        is_active: false,
        link: {
          name: 'supervisor.dispositions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-unknow-line'
      },
      {
        title: 'Unsuccessful Reasons',
        is_active: false,
        link: {
          name: 'supervisor.unsuccessful-reasons'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-error-warning-line'
      },
      {
        title: 'Promotions',
        is_active: false,
        link: {
          name: 'supervisor.promotions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-voice-line'
      },
      {
        title: 'Campaigns',
        is_active: false,
        link: {
          name: 'supervisor.campaigns'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Custom Fields',
        is_active: false,
        link: {
          name: 'supervisor.custom-fields'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-input-method-line'
      },
      {
        title: 'Agent Groups',
        is_active: false,
        link: {
          name: 'supervisor.agent-groups'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-user-line'
      },
      {
        title: 'Agents',
        is_active: false,
        link: {
          name: 'supervisor.agents'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-voice-line'
      },
      {
        title: 'Marketing Programs',
        is_active: false,
        link: {
          name: 'supervisor.marketing-programs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-voice-line'
      }
    ]
  },
  {
    title: 'Farmer Reports',
    name: 'sidebar.supervisor.farmer-reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Call Logs',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-call-logs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Call Summaries',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-call-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Connected Summaries',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-connected-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Unsuccessful Summaries',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-unsuccessful-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Promotion Summaries',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-promotion-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'SMS Summaries',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-sms-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Satisfaction Surveys',
        is_active: false,
        link: {
          name: 'supervisor.farmer-report-satisfaction-surveys'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  },
  {
    title: 'Dealer Reports',
    name: 'sidebar.supervisor.dealer-reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Call Logs',
        is_active: false,
        link: {
          name: 'supervisor.dealer-report-call-logs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Call Summaries',
        is_active: false,
        link: {
          name: 'supervisor.dealer-report-call-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Connected Summaries',
        is_active: false,
        link: {
          name: 'supervisor.dealer-report-connected-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Unsuccessful Summaries',
        is_active: false,
        link: {
          name: 'supervisor.dealer-report-unsuccessful-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  },
  {
    title: 'Statistics',
    name: 'sidebar.supervisor.statistics',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-chart-line',
    children: [
      {
        title: 'CRM Statistics',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.crm-statistics'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-customer-service-2-line'
      },
      {
        title: 'Customer Information',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.customer-information'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-account-circle-line'
      },
      {
        title: 'Crops',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-crops'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-stack-line'
      },
      {
        title: 'Crop Varieties',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-crop-varieties'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-device-line'
      },

      {
        title: 'Herbicides',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-herbicide-ones'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-function-line'
      },

      {
        title: 'Herbicides (Dike)',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-herbicide-twos'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-function-fill'
      },
      {
        title: 'Fungicides',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-fungicides'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contrast-drop-line'
      },
      {
        title: 'Insecticides',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-insecticides'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-bug-line'
      },
      {
        title: 'Fertilizers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-fertilizers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-paint-line'
      },
      {
        title: 'Crop Prices',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-crop-prices'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-money-dollar-circle-line'
      },
      {
        title: 'Raisers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'supervisor.graph-raisers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-baidu-line'
      }
    ]
  }

]

export default SupervisorSideMenu
