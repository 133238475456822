const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Persons',
    name: 'sidebar.admin.persons',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'Users',
        is_active: false,
        link: {
          name: 'admin.users'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Lead Agents',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.lead-agents'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'DSM Accounts',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.dsm-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Dealers',
        is_active: false,
        link: {
          name: 'admin.dealers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Customers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.customers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-account-circle-line'
      },
      {
        title: 'Big Land Owners',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.big-land-owners'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-account-circle-line'
      },
      {
        title: 'Raw Data',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.raw-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-database-2-line'
      }
    ]
  },
  {
    title: 'Utilities',
    name: 'sidebar.admin.utilities',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line',
    children: [
      {
        title: 'SMS',
        is_active: false,
        link: {
          name: 'admin.sms'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-mail-send-line'
      }
    ]
  },
  {
    title: 'Places',
    name: 'sidebar.admin.places',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Regions',
        is_active: false,
        link: {
          name: 'admin.regions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Provinces',
        is_active: false,
        link: {
          name: 'admin.provinces'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Municipalities',
        is_active: false,
        link: {
          name: 'admin.municipalities'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Barangays',
        is_active: false,
        link: {
          name: 'admin.barangays'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Clusters',
        is_active: false,
        link: {
          name: 'admin.clusters'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Areas',
        is_active: false,
        link: {
          name: 'admin.areas'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Distributions',
        is_active: false,
        link: {
          name: 'admin.distributions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      }
    ]
  },
  {
    title: 'Crops',
    name: 'sidebar.admin.crops',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Categories',
        is_active: false,
        link: {
          name: 'admin.categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-crop-line'
      },
      {
        title: 'Classifications',
        is_active: false,
        link: {
          name: 'admin.classifications'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-crop-2-line'
      },
      {
        title: 'Varieties',
        is_active: false,
        link: {
          name: 'admin.varieties'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Fertilizers',
        is_active: false,
        link: {
          name: 'admin.fertilizers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-shopping-bag-line'
      },
      {
        title: 'Herbicides',
        is_active: false,
        link: {
          name: 'admin.herbicides'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      },
      {
        title: 'Fungicides',
        is_active: false,
        link: {
          name: 'admin.fungicides'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-dashboard-line'
      },
      {
        title: 'Insecticides',
        is_active: false,
        link: {
          name: 'admin.insecticides'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-bug-line'
      },
      {
        title: 'Field Sizes',
        is_active: false,
        link: {
          name: 'admin.field-sizes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-crop-2-line'
      }
    ]
  },
  {
    title: 'Maintenances',
    name: 'sidebar.admin.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Mobile Prefixes',
        is_active: false,
        link: {
          name: 'admin.mobile-prefixes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-settings-line'
      },
      {
        title: 'Communications',
        is_active: false,
        link: {
          name: 'admin.communications'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-phone-line'
      },
      {
        title: 'Payments',
        is_active: false,
        link: {
          name: 'admin.payments'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-money-dollar-box-line'
      },
      {
        title: 'Dialects',
        is_active: false,
        link: {
          name: 'admin.dialects'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-voice-line'
      },
      {
        title: 'Visitation Purposes',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.visitation-purposes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Ticket Categories',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.ticket-categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-barcode-box-fill'
      }
    ]
  },
  {
    title: 'Products',
    name: 'sidebar.admin.products',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-scan-line',
    children: [
      {
        title: 'Categories',
        is_active: false,
        link: {
          name: 'admin.product-categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-crop-line'
      },
      {
        title: 'Products',
        is_active: false,
        link: {
          name: 'admin.products'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      }
    ]
  },
  {
    title: 'Customer Data',
    name: 'sidebar.admin.customer-data',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Export by Group',
        is_active: false,
        link: {
          name: 'admin.export-by-group'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default AdminSideMenu
