<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link
            :to="{ name: `${user ? user.role : 'admin'}.dashboard`}"
            class="logo"
          >
            <div class="iq-light-logo">
              <img
                src="@/assets/images/logo.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <div class="iq-dark-logo">
              <img
                src="@/assets/images/logo-dark.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <span>{{ appName }}</span>
          </router-link>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0 d-flex justify-content-between">
        <div
          class="d-inline-block"
          style="height: 75px"
        >
          <div class="d-flex h-100 p-3">
            <div
              class="wrapper-menu align-self-center p-2 rounded"
              style="background: #f8f7fd;"
              @click="sidebarMini"
            >
              <div class="main-circle">
                <i class="ri-menu-2-line" />
              </div>
              <div class="hover-circle">
                <i class="ri-menu-2-line" />
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-list">
          <li
            v-nav-toggle
            class="rounded"
          >
            <a
              href="javascript:void(0)"
              class="search-toggle iq-waves-effect d-flex align-items-center bg-success rounded px-2"
            >
              <!-- <img
                :src="transformToImageUrl(getProfile())"
                class="img-fluid rounded mr-3"
                alt="user"
              > -->
              <b-avatar
                :text="getUserAbrevation"
                size="lg"
                variant="light"
                rounded
                class="font-weight-bolder"
              />
              <div
                class="caption"
                style="min-width: 195px; margin-left: 14.5px;"
              >
                <h6 class="mb-0 line-height text-white">
                  {{ user ? user.name : null }}
                </h6>
                <span class="font-size-12 text-white">
                  {{ user ? user.role : null }}
                </span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown mt-1">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-success text-center p-3 d-block d-lg-none d-md-none">
                    <h5 class="mb-0 text-white line-height">
                      {{ user ? user.name : null }}
                    </h5>
                    <span class="font-size-12 text-white">
                      {{ user ? user.role : null }}
                    </span>
                  </div>
                  <b-overlay
                    rounded
                    opacity="1"
                    spinner-variant="success"
                    :show="state.busy"
                  >
                    <router-link
                      :to="{name: 'shared.change-password'}"
                      class="iq-sub-card iq-bg-success-hover"
                    >
                      <div class="media align-items-center">
                        <div class="media-body d-flex px-2">
                          <i class="ri-key-2-line px-2 h5" />
                          <h6 class="mb-0 ">
                            Change Password
                          </h6>
                        </div>
                      </div>
                    </router-link>
                    <!-- <router-link
                      :to="{name: 'shared.change-password'}"
                      class="iq-sub-card iq-bg-success-hover"
                    >
                      <div class="media align-items-center">
                        <div class="media-body d-flex px-2">
                          <i class="ri-exchange-line px-2 h5" />
                          <h6 class="mb-0 ">
                            Switch Account
                          </h6>
                        </div>
                      </div>
                    </router-link> -->
                    <b-link
                      href="javascript:void(0)"
                      class="iq-sub-card iq-bg-success-hover"
                      :disabled="state.busy"
                      @click="logout"
                    >
                      <div class="media align-items-center">
                        <div class="media-body d-flex px-2 text-danger">
                          <i class="ri-shut-down-line px-2 h5" />
                          <h6 class="mb-0 text-danger">
                            Log Out
                          </h6>
                        </div>
                      </div>
                    </b-link>
                  </b-overlay>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import GlobalSearch from '../../search/GlobalSearch'
import { core, APPNAME } from '@/config/pluginInit'
// import Lottie from '@/components/core/lottie/Lottie'
import { SharedAuthService } from '@/services'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'HeaderStyle',

  components: {
    // HoverMenu,
    // GlobalSearch,
    // CollapseMenu
    // Lottie
  },

  mixins: [url, misc],

  data () {
    return {
      state: {
        busy: false
      },
      appName: APPNAME,
      globalSearch: '',
      notification: [],
      message: []
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    getUserAbrevation () {
      if (this.user?.name) {
        const fullname = this.user.name.split(' ')
        return fullname[0][0]
      }
      return ''
    }
  },

  mounted () {
    if (!this.horizontal) {
      document.addEventListener('click', this.closeSearch, true)
    }
  },
  methods: {
    sidebarMini () {
      core.triggerSet()
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null) {
        document.getElementById('searchbox-datalink').classList.add('show-data')
      }
    },
    closeSearch (event) {
      const classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null && document.getElementById('searchbox-datalink') !== undefined) {
        document.getElementById('searchbox-datalink').classList.remove('show-data')
      }
    },
    async logout () {
      if (!this.state.busy) {
        this.state.busy = true
        await SharedAuthService.Logout().then(() => {
          this.state.busy = false
          this.$store.dispatch('auth/logout')
          this.$router.replace({ name: 'shared.login' })
        })
      }
    },
    ...mapActions({
      rtlAdd: 'setting/setRtlAction',
      rtlRemove: 'setting/removeRtlAction'
    })
  }
}
</script>
