const UserSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Leads',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.leads'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-file-user-line'
  },
  {
    title: 'Raw Data',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.raw-datas'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'With Mobile Number',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.raw-data.mobile-number'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-smartphone-line'
  },
  {
    title: 'Facebook Only',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.raw-data.facebook'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-facebook-box-line'
  },
  {
    title: 'Activities',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.activities'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Visits',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.visits'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-walk-line'
  },
  {
    title: 'Call Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.call-requests'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-phone-line'
  }
]

export default UserSideMenu
